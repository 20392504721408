import React, {useState} from 'react';
import "./Container.css";
import {useAuth} from "../../auth"
import logoImage from "../../images/logo.png";
import {Link, useHistory} from "react-router-dom";
import Icon from '@mdi/react';
import {mdiAccount, mdiHelp, mdiQrcodeScan} from '@mdi/js';
import {useStore} from '../../store';
import LoadingOverlay from "./LoadingOverlay";


export default function Container(props) {
    const [menuVisible, setMenuVisible] = useState(false);
    const [error, setError, loading] = useStore(state => [state.error, state.setError, state.loading]);
    const history = useHistory();
    const auth = useAuth();

    let sidebar = props.sidebar;
    if (!sidebar) {
        sidebar = [];
    }

    let mobileMenu = props.mobileMenu;
    if (!mobileMenu) {
        mobileMenu = [];
    }

    function toggleMenu(evt) {
        evt.preventDefault();
        setMenuVisible(!menuVisible)
    }

    function logout() {
        auth.signout();
        localStorage.setItem("loggedOut", "true");
        // history.replace("/");
    }

    return (
        <>
            <div className="Container">
                <div className="Header">
                    <div className="Header-Logo">
                        <Link to="/">
                            <img src={logoImage} alt="Logo" width="50" height="50"/>
                        </Link>
                    </div>

                    <div className="Header-Mobile-Menu">
                        {
                            mobileMenu.map(value => (
                                <div className="Header-Mobile-Menu-Item" key={value.key}>
                                    {value}
                                </div>
                            ))
                        }

                        <div className="Header-Mobile-Menu-Item">
                            <Link to="/scanner">
                                <Icon path={mdiQrcodeScan}/>
                            </Link>
                        </div>

                        <div className="Header-Mobile-Menu-Item">
                            <Link to="#">
                                <Icon path={mdiHelp}/>
                            </Link>
                        </div>
                    </div>


                    <div className="Header-Menu-Link">
                        <a href={window.location.href} onClick={toggleMenu}>
                            <div className="Header-Menu-Link-Icon">
                                <Icon path={mdiAccount}/>
                            </div>
                            <div className="Header-Menu-Link-Text">
                                Profile
                            </div>
                        </a>
                    </div>
                    <div className={`Menu Header-Menu ${!menuVisible ? "Hidden" : ""}`}>
                        <ul>
                            {auth.data.role === "admin" && !document.location.pathname.startsWith("/admin") ? <li><Link to="/admin">Admin</Link></li> : ""}
                            <li><a href={window.location.href} onClick={logout}>Log out</a></li>
                        </ul>
                    </div>
                    <div className="Header-Bottom-Border"/>
                </div>
                <div className="Main">
                    <div className="Sidebar">
                        <ul className={"Sidebar-Menu"}>
                            {
                                sidebar.map(value => (
                                    <li key={value.text}>
                                        <Link to={value.link ?? document.location.pathname}>
                                            <Icon path={value.icon}/>
                                            <span>{value.text}</span>
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="Sidebar-Help">
                            <Link to="#">
                                ?
                            </Link>
                        </div>
                    </div>
                    <div className="Content">
                        {loading ? <LoadingOverlay/> : ""}
                        {error ?
                            <div className="Content-Overlay"><h1 className="Content-Error">{error} (<a href={document.location.href} onClick={() => setError(null)}>dismiss error</a>)</h1></div> : ""}
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}