import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {useAuth} from "./auth";
import Login from "./components/Login/Login";
import SetPassword from "./components/SetPassword/SetPassword"
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import AdminWorkshopOverview from "./components/AdminWorkshopOverview/AdminWorkshopOverview";
import WorkshopOverview from "./components/WorkshopOverview/WorkshopOverview";
import ModuleBrowser from "./components/ModuleBrowser/ModuleBrowser";
import QRCodeScanner from "./components/QRCodeScanner/QRCodeScanner";
import AdminLibrary from "./components/AdminLibrary/AdminLibrary";
import QRCodeRedirector from "./components/QRCodeRedirector/QRCodeRedirector";
import AdminWorkshopEditor from "./components/AdminWorkshopEditor/AdminWorkshopEditor";
import {setOnError, setOnLoading, setToken, token} from "./api/api";
import {useStore} from "./store";


export default function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <PrivateRoute path="/admin/overview/:workshopId/modules/:moduleId/:path+">
                        <ModuleBrowser root="/admin/overview"/>
                    </PrivateRoute>

                    <PrivateRoute path="/admin/overview/:workshopId/modules/:moduleId">
                        <ModuleBrowser root="/admin/overview"/>
                    </PrivateRoute>

                    <PrivateRoute path="/admin/overview/:workshopId">
                        <AdminWorkshopEditor/>
                    </PrivateRoute>

                    <PrivateRoute path="/admin/overview">
                        <AdminWorkshopOverview/>
                    </PrivateRoute>

                    <PrivateRoute path="/admin/library/:moduleId/:path+">
                        <ModuleBrowser root="/admin/library"/>
                    </PrivateRoute>

                    <PrivateRoute path="/admin/library/:moduleId">
                        <ModuleBrowser root="/admin/library"/>
                    </PrivateRoute>

                    <PrivateRoute path="/admin/library">
                        <AdminLibrary/>
                    </PrivateRoute>

                    <PrivateRoute path="/scanner">
                        <QRCodeScanner/>
                    </PrivateRoute>

                    <PrivateRoute path="/workshops/:workshopId/modules/:moduleId/:path+">
                        <ModuleBrowser root="/workshops"/>
                    </PrivateRoute>

                    <PrivateRoute path="/workshops/:workshopId/modules/:moduleId/">
                        <ModuleBrowser root="/workshops"/>
                    </PrivateRoute>

                    <PrivateRoute path="/workshops/:workshopId">
                        <WorkshopOverview/>
                    </PrivateRoute>

                    <PrivateRoute path="/qr/v:version(\d+)/:id">
                        <QRCodeRedirector/>
                    </PrivateRoute>

                    <Route path="/login">
                        <Login/>
                    </Route>

                    <Route path="/forgot-password">
                        <ForgotPassword/>
                    </Route>

                    <Route path="/set-password">
                        <SetPassword/>
                    </Route>

                    <Route exact path="/">
                        <Root/>
                    </Route>

                    <Route>
                        <Redirect to="/"/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

function PrivateRoute({children, ...rest}) {
    const auth = useAuth();
    const [setError, setLoading] = useStore(state => [state.setError, state.setLoading])

    if(auth.data) {
        setToken(auth.data.token);
        setOnError(setError);
        setOnLoading(setLoading);
    }

    return (
        <Route
            {...rest}
            render={({location}) =>
                auth.data?.token ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

function Root() {
    const auth = useAuth();

    if (auth.data) {
        if (auth.data.role === "admin") {
            return <Redirect to="/admin/overview"/>
        } else {
            return <Redirect to={`/workshops/${auth.data.workshopId}/modules/${auth.data.currentModuleId}`}/>
        }
    } else {
        return <Redirect to="/login"/>
    }
}
