import {useStore} from "./store";

export const useAuth = () => {
    const [auth, setAuth] = useStore(state => [state.auth, state.setAuth]);

    const signin = (email, password) => {
        return fetch(process.env.REACT_APP_API_URL + '/user/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password,
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    throw data.error;
                }
                document.cookie = `token=${data.token}; Path=/; SameSite=Strict; Secure; Max-Age=86400`; // 24 hours
                setAuth(data);
                localStorage.setItem("auth", JSON.stringify(data));
            });
    };
    const signout = () => {
        setAuth(null);
        document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        localStorage.removeItem("auth");
    }
    return {
        data: auth,
        signin,
        signout,
    };
}